var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType,
      "editable": ""
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly || _vm.editMode,
      "label": "Navn",
      "required": "",
      "rules": [function (v) {
        return !!v || 'Fyll inn kategori';
      }]
    },
    model: {
      value: _vm.categoryModel.name,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "name", $$v);
      },
      expression: "categoryModel.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Beskrivelse"
    },
    model: {
      value: _vm.categoryModel.description,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "description", $$v);
      },
      expression: "categoryModel.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Fleksfelt 1"
    },
    model: {
      value: _vm.categoryModel.attribute1,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute1", $$v);
      },
      expression: "categoryModel.attribute1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Fleksfelt 2"
    },
    model: {
      value: _vm.categoryModel.attribute2,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute2", $$v);
      },
      expression: "categoryModel.attribute2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Fleksfelt 3"
    },
    model: {
      value: _vm.categoryModel.attribute3,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute3", $$v);
      },
      expression: "categoryModel.attribute3"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Fleksfelt 4"
    },
    model: {
      value: _vm.categoryModel.attribute4,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute4", $$v);
      },
      expression: "categoryModel.attribute4"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "readonly": _vm.readonly,
      "label": "Fleksfelt 5"
    },
    model: {
      value: _vm.categoryModel.attribute5,
      callback: function callback($$v) {
        _vm.$set(_vm.categoryModel, "attribute5", $$v);
      },
      expression: "categoryModel.attribute5"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }