
import { api } from "@/api/api";
import { ApiCreateTeachingMaterialCategoryDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "TeachingMaterialCategoriesModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiCreateTeachingMaterialCategoryDto>,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const categoryModel = ref<ApiCreateTeachingMaterialCategoryDto>({
      name: "",
      description: "",
      attribute1: "",
      attribute2: "",
      attribute3: "",
      attribute4: "",
      attribute5: "",
    });

    const handleSubmit = () => {
      const isValid = (refs.connectForm as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        addCategory();
      } else if (props.modalType === ModalType.Edit) {
        editCategory();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    const addCategory = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.teachingmaterialcategory.createTeachingMaterialCategoryAsync(categoryModel.value);
        openNotification(store, NotificationItemType.Success, "Kategori lagt til");
        emit("close");
      });
    };

    const editCategory = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!categoryModel.value.name) {
          throw new Error("Missing name on edit");
        }
        await api.teachingmaterialcategory.updateTeachingMaterialCategoryAsync(
          categoryModel.value.name,
          categoryModel.value
        );
        openNotification(store, NotificationItemType.Success, `${categoryModel.value.name} oppdatert`);
        emit("close");
      });
    };

    if (props.existingItemModal && (props.modalType === ModalType.Edit || props.modalType === ModalType.Display)) {
      categoryModel.value = deepCloneObject(props.existingItemModal);
    }

    return {
      categoryModel,
      handleSubmit,
      readonly: computed(() => props.modalType === ModalType.Display),
      editMode: computed(() => props.modalType === ModalType.Edit),
    };
  },
});
